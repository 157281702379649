import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';

interface RouteProps {
  name: string,
  icon: string,
  route?: string;
  group?: RouteProps[]
  openGroup?: boolean
}

@Component({
  selector: 'header-admin',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  search: string = '';
  pages: RouteProps[] = []

  constructor(
    public auth: AuthService,
    public global: GlobalService,
    public router: Router
  ) {
    this.buildPages();
  }

  ngOnInit() {
  }

  goTo(page: any) {
    if (page.route) {
      this.router.navigate([page.route]).then(() => {
        this.global.menuOpen = false;
      });
    } else {
      page.openGroup = !page.openGroup;
    }
  }

  logout() {
    this.router.navigate(['/login']).then(() => {
      this.global.menuOpen = false;
      this.auth.logout();
    })
  }

  onSearchChange(ev) {
    if (!ev) {
      this.buildPages();
    }
  }

  buildPages() {
    this.pages = [
      { name: 'Home', icon: 'fas fa-home', route: '/' },
      { name: 'Banners', icon: 'fas fa-image', route: '/banners' },
      { name: 'Blogs', icon: 'fas fa-newspaper', route: '/blogs' },
      { name: 'Clientes', icon: 'fas fa-user-tie', route: '/clientes' },
      { name: 'Depoimentos', icon: 'fas fa-comments', route: '/depoimentos' },
      { name: 'Diferenciais', icon: 'fas fa-balance-scale-right', route: '/diferenciais' },
      { name: 'Indicadores', icon: 'fas fa-plus', route: '/indicadores' },
      { name: 'Parceiros', icon: 'fas fa-user-friends', route: '/parceiros' },
      { name: 'Linhas do Tempo', icon: 'fas fa-clock', route: '/linhas-do-tempo' },
      { name: 'Mensagens', icon: 'fas fa-comments', route: '/mensagens' },
      { name: 'Sistemas', icon: 'fas fa-suitcase', route: '/sistemas' },
      { name: 'Vídeos', icon: 'fas fa-video', route: '/videos' },
      { name: 'Sobre', icon: 'fas fa-info', route: '/sobre' },
      { name: 'Usuários', icon: 'fas fa-user-shield', route: '/usuarios' },
      { name: 'Contato Geral', icon: 'fas fa-phone-alt', route: '/contato-geral' },
      { name: 'Cadastro Geral', icon: 'fas fa-cog', route: '/cadastro-geral' },
      {
        name: 'Configurações',
        icon: 'fas fa-cogs',
        group: [
          { name: 'Geral', icon: 'fas fa-cog', route: '/configuracao-geral' },
          { name: 'E-mail', icon: ' fas fa-paper-plane', route: '/configuracao-email' },
        ],
        openGroup: false
      },
    ]
  }

  getContrast = (hexcolor) => {

    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === '#') {
      hexcolor = hexcolor.slice(1);
    }

    // If a three-character hexcode, make six-character
    if (hexcolor.length === 3) {
      hexcolor = hexcolor.split('').map(function (hex) {
        return hex + hex;
      }).join('');
    }

    // Convert to RGB value
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);

    // Get YIQ ratio
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    // Check contrast
    return (yiq >= 128) ? 'black' : 'white';
  };

}
