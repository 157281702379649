import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export enum HeaderStyle {
  LIGHT = "light-content",
  DARK = "dark-content",
}

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  apiUrl: string = 'https://api.aheadsistemas.com.br';
  imageUrl: string = 'https://api.aheadsistemas.com.br/'
  menuOpen: boolean = false;

  headerStyle = new Subject<HeaderStyle>();
  openSistemaModal = new BehaviorSubject<boolean>(false);

  constructor() { }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

}
