import { Component, OnInit } from '@angular/core';
import { fadeAnimation } from 'src/animations/fade';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [fadeAnimation]
})
export class MainComponent implements OnInit {

  getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  constructor() { }

  ngOnInit(): void {
  }

}
