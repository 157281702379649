import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Banner } from 'src/app/models/banner.model';
import { Beneficio } from 'src/app/models/beneficio.model';
import { Blog } from 'src/app/models/blog.model';
import { CadastroGeral } from 'src/app/models/cadastro-geral.model';
import { Cliente } from 'src/app/models/cliente.model';
import { ContatoGeral } from 'src/app/models/contato-geral.model';
import { ContatoMensagem } from 'src/app/models/contato-mensagem.model';
import { Depoimento } from 'src/app/models/depoimento.model';
import { Diferencial } from 'src/app/models/diferencial.model';
import { GaleriaVideo } from 'src/app/models/galeria-video.model';
import { Indicador } from 'src/app/models/indicador.model';
import { LinhaDoTempo } from 'src/app/models/linha-do-tempo.model';
import { Parceiro } from 'src/app/models/parceiro.model';
import { Sistema } from 'src/app/models/sistema.model';
import { Sobre } from 'src/app/models/sobre.model';
import { ApiService } from '../api.service';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    public api: ApiService,
    public global: GlobalService
  ) { }

  getBanners(numeroPagina: number, registroPorPagina: number): Observable<Banner[]> {
    const params = {
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina
    }
    return this.api.get(`/site/banner/buscar`, params) as any;
  }

  getContatoGeral(): Observable<ContatoGeral> {
    return this.api.get(`/site/contatoGeral/buscar`) as any;
  }

  getCadastroGeral(): Observable<CadastroGeral> {
    return this.api.get(`/site/cadastroGeral/buscar`) as any;
  }

  getBeneficios(numeroPagina: number, registroPorPagina: number): Observable<Beneficio[]> {
    const params = {
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina
    }
    return this.api.get(`/site/beneficio/buscar`, params) as any;
  }

  getDepoimentos(numeroPagina: number, registroPorPagina: number): Observable<Depoimento[]> {
    const params = {
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina
    }
    return this.api.get(`/site/depoimento/buscar`, params) as any;
  }

  getDiferenciais(numeroPagina: number, registroPorPagina: number): Observable<Diferencial[]> {
    const params = {
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina
    }
    return this.api.get(`/site/diferencial/buscar`, params) as any;
  }

  getParceiros(numeroPagina: number, registroPorPagina: number): Observable<Parceiro[]> {
    const params = {
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina
    }
    return this.api.get(`/site/parceiro/buscar`, params) as any;
  }

  getIndicadores(numeroPagina: number, registroPorPagina: number): Observable<Indicador[]> {
    const params = {
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina
    }
    return this.api.get(`/site/indicador/buscar`, params) as any;
  }

  getLinhasDoTempo(numeroPagina: number, registroPorPagina: number): Observable<LinhaDoTempo[]> {
    const params = {
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina
    }
    return this.api.get(`/site/linhaDoTempo/buscar`, params) as any;
  }

  getClientes(numeroPagina: number, registroPorPagina: number): Observable<Cliente[]> {
    const params = {
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina
    }
    return this.api.get(`/site/cliente/buscar`, params) as any;
  }

  getSobre(): Observable<Sobre> {
    return this.api.get(`/site/sobre/buscar`) as any;
  }

  getBlogs(numeroPagina: number, registroPorPagina: number): Observable<Blog[]> {
    const params = {
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina
    }
    return this.api.get(`/site/blog/buscar`, params) as any;
  }

  getBlogsRelacionados(numeroPagina: number, registroPorPagina: number, idBlog: number): Observable<Blog[]> {
    const params = {
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
      idBlog: idBlog,
    }
    return this.api.get(`/site/blog/buscarUltimas`, params) as any;
  }

  getBlog(slug: string): Observable<Blog> {
    return this.api.get(`/site/blog/buscar`, { slug: slug }) as any;
  }

  getSistemas(numeroPagina: number, registroPorPagina: number): Observable<Sistema[]> {
    const params = {
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
    }
    return this.api.get(`/site/sistema/buscar`, params) as any;
  }

  getSistema(slug: string): Observable<Sistema> {
    return this.api.get(`/site/sistema/buscar`, { slug: slug }) as any;
  }

  getVideos(numeroPagina: number, registroPorPagina: number): Observable<GaleriaVideo[]> {
    const params = {
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
    }
    return this.api.get(`/site/galeriaVideo/buscar`, params) as any;
  }

  inserirContatoMensagem(mensagem: ContatoMensagem): Observable<any> {
    return this.api.post(`/site/contatoMensagem/inserir`, mensagem);
  }
}
