<header class="header" [class.active]="active" [class.light-content]="headerStyle === 'light-content'"
    [class.dark-content]="headerStyle === 'dark-content'">
    <div class="container">
        <nav class="header-nav">
            <a class="logo" routerLink="/">
                <img [src]="(active || headerStyle === 'dark-content') ? 'assets/logo.png' : 'assets/logo-2.svg'"
                    alt="Ahead Sistemas | Sistemas para reduzir custos e ganhar tempo" class="logo__pic hidden-mobile">
                <img src="assets/logo.png" alt="Ahead Sistemas | Sistemas para reduzir custos e ganhar tempo"
                    class="logo__pic hidden-desktop">
            </a>
            <button class="header-menu__toggle hidden-desktop" (click)="openMenu = true">
                <i class="fas fa-bars"></i>
            </button>
            <ul class="header-menu" [class.active]="openMenu">
                <li class="header-menu__item">
                    <a class="header-menu__link maven" (click)="openMenu = false" routerLink="/"
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                        [class.hide-active]="lastFragment">
                        Home
                    </a>
                </li>
                <li class="header-menu__item">
                    <a class="header-menu__link maven" (click)="openMenu = false" routerLink="/sobre"
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        Sobre
                    </a>
                </li>
                <li class="header-menu__item hidden-mobile">
                    <a class="header-menu__link maven" (click)="openMenu = false" routerLink="/" fragment="sistemas"
                        [class.active]="lastFragment === 'sistemas'">
                        Sistemas
                    </a>
                    <div class="submenu">
                        <a class="submenu-item" *ngFor="let s of sistemas;" [routerLink]="['/sistema', s.slug]">
                            <img [src]="global.imageUrl+s.icone" [alt]="s.nome">
                            <span class="maven">{{s.nome}}</span>
                        </a>
                    </div>
                </li>
                <li class="header-menu__item hidden-desktop">
                    <a class="header-menu__link maven" (click)="openSubmenu = !openSubmenu"
                        [class.active]="openSubmenu">
                        <span>Sistemas</span>
                        <i class="fas fa-chevron-down" *ngIf="!openSubmenu"></i>
                        <i class="fas fa-chevron-up" *ngIf="openSubmenu"></i>
                    </a>
                    <div class="submenu" [@detailExpand]="openSubmenu ? 'expanded' : 'collapsed'">
                        <a class="submenu-item" *ngFor="let s of sistemas;" [routerLink]="['/sistema', s.slug]"
                            (click)="openMenu = false">
                            <img [src]="global.imageUrl+s.icone" [alt]="s.nome">
                            <span class="maven">{{s.nome}}</span>
                        </a>
                    </div>
                </li>
                <li class="header-menu__item">
                    <a class="header-menu__link maven" (click)="openMenu = false" routerLink="/" fragment="clientes"
                        [class.active]="lastFragment === 'clientes'">
                        Clientes
                    </a>
                </li>
                <li class="header-menu__item">
                    <a class="header-menu__link maven" (click)="openMenu = false" routerLink="/blog"
                        routerLinkActive="active">
                        Blog
                    </a>
                </li>
                <li class="header-menu__item">
                    <a class="header-menu__link maven" (click)="openMenu = false" routerLink="/contato"
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        Contato
                    </a>
                </li>
            </ul>
            <div class="header-menu-backdrop hidden-desktop" [class.active]="openMenu" (click)="openMenu = false"></div>
        </nav>
    </div>
</header>