import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  active: boolean = false;
  color: string;

  constructor() { }

  present(color?) {
    this.color = color ? color : '#AE0721';
    
    this.active = true;
  }

  dismiss() {
    this.active = false;
  }
}
