import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Sistema } from 'src/app/models/sistema.model';
import { GlobalService } from 'src/app/services/global.service';
import { DataService } from 'src/app/services/website/data.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { isPlatformServer } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'header-website',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class HeaderComponent implements OnInit {

  active: boolean = false;
  openMenu: boolean = false;
  openSubmenu: boolean = false;
  headerStyle: string = 'light-content';

  lastFragment: string;

  @HostListener('window:scroll', [])
  onScroll(): void {

    if (isPlatformServer(this.platformId)) return;

    const windowScroll = window.pageYOffset;
    if (windowScroll >= 50) {
      this.active = true;
    } else {
      this.active = false;
    }
  }

  sistemas: Sistema[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    public global: GlobalService,
    public _data: DataService,
    public route: ActivatedRoute,
  ) {
    this.global.headerStyle.subscribe(style => this.headerStyle = style);
  }

  ngOnInit(): void {
    this.getSistemas();
    this.route.fragment.subscribe(frag => {
      this.lastFragment = frag;
    })
  }

  getSistemas() {
    this._data.getSistemas(-99, -99).subscribe(res => this.sistemas = res);
  }

}
