<section class="spinner" [class.active]="_spinner.active" [style.background-color]="_spinner.color">
    <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</section>