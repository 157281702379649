import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    public auth: AuthService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.auth.token = localStorage.getItem('token_ahead');
      this.auth.user = this.auth.getUserStorage();
    }
  }
}
